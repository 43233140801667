import React, { useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import pdf from "../../assests/ELCfinal.pdf";
import "@react-pdf-viewer/core/lib/styles/index.css";
import axios from "axios";
import Spinner from "../facultydashboard/formGroup/Spinner";
import Topbar from "../landing/Topbar";
import Navbar from "../../components/Navbar";
import Breadcrumbs from "../../components/Breadcrumbs";
import Footer from "../../Footer";

const ElcMembers = () => {
  const [datas, setData] = React.useState({});
  const [loader, setLoader] = useState(true);
  // console.log(datas);
  const siteData = () => {
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0]);
        setLoader(false);
        // .
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  React.useEffect(() => {
    siteData();
  }, []);
  return (
    <div>
      {loader && <Spinner />}
      <Topbar />
      <Navbar datas={datas} />
      <Breadcrumbs
        page={"Electoral Literacy Clubs Members"}
        pagelink="#"
        sublink="#"
        subpage={"More"}
      />{" "}
      <div className="mx-auto py-5 px-4 w-full max-w-7xl border-b-2 border-gray-200">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
          <div
            style={{
              border: "1px solid rgba(0, 0, 0, 0.3)",
              height: "750px",
            }}
          >
            <Viewer fileUrl={pdf} />
          </div>
        </Worker>
      </div>
      <Footer datas={datas} />
    </div>
  );
};

export default ElcMembers;
