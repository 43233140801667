import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";

const ElcAccordion = () => {
  const accData = [
    {
      _id: 1,
      section: "Objectives",
      points: [
        "To educate the targeted populations about voter registration, electoral process, and related matters through hands-on experience.",
        "To facilitate EVM and VVPAT familiarization and educate about the robustness of EVMs and the integrity of the electoral process using EVMs.",
        "To help the target audience understand the value of their vote and exercise their right to franchise in a confident, comfortable, and ethical manner.",
        "To harness the potential of ELC members for carrying the electoral literacy in communities.",
        "To facilitate voter registration for its eligible members who are not yet registered.",
        "To develop a culture of electoral participation and maximize informed and ethical voting, following the principles 'Every vote counts' and 'No Voter to be Left Behind'.",
      ],
    },
    {
      _id: 2,
      section: "Members",
      details:
        "The club will have students as its members. One can become a member by registering at the beginning of the academic year. After registration, one is recognized as a member till the completion of their graduation course.",
    },
    {
      _id: 3,
      section: "Structure",
      details: [
        "ELC may be named as per the name of the College/Institute and will be coordinated by the Nodal Officer.",
        "It is proposed that ELC be set up per college. Chapters of the same can be introduced for different departments/schools within the university if needed.",
        "The institution may decide how to proceed as per its convenience.",
      ],
    },
    {
      _id: 4,
      section: "Nodal Officer / Mentor",
      points: [
        "One or two teachers from the Department of the college will act as the Nodal Officers for the ELC.",
        "They will promote and supervise Club enrollment.",
        "They will supervise the election and formation of the Executive Committee of ELC.",
        "They will coordinate between the District Election Officer and the ELC for the exchange of resources and information.",
        "They will attempt to generate new resources and forward the same to the District Election Officer.",
        "They will guide and supervise the development of the calendar of activities for the year by the Executive Council.",
      ],
    },
    {
      _id: 5,
      section: "Executive Committee",
      points: [
        "The club would be run by an elected body from among the ELC member students with elected representatives from each class.",
        "Committee members must be students who are already enrolled as voters.",
        "The committee must not include any politically active student, but students with political affiliations may enroll as common members.",
        "The elected representatives will constitute the Executive Committee of the ELC and elect a Chairman and Vice Chairman.",
        "The Executive Committee, under the guidance of the Nodal Officer, will be responsible for organizing the activities of the ELC.",
        "The elected body will generate the culture of electoral participation among the ELC members.",
      ],
    },
    {
      _id: 6,
      section: "Convener",
      points: [
        "Campus Ambassadors, where appointed, will act as Conveners and assist the Nodal Officer.",
        "In the absence of Campus Ambassadors, the Nodal Officers and Executive Committee may nominate interested students as Conveners.",
        "The Convener will be responsible for involving student members in organizing various ELC activities.",
        "They will conduct activities under the guidance of the Executive Committee and Mentor/Nodal Officer.",
        "They will perform any other task assigned by the Executive Committee and Mentor/Nodal Officer.",
      ],
    },
    {
      _id: 7,
      section: "Term of Office",
      points: [
        "The Executive Committee and Convener(s) would have a tenure of 1 year extending to 2 years.",
        "The representative must be registered as a member of the ELC.",
      ],
    },
    {
      _id: 8,
      section: "Venue",
      details:
        "The venue for ELC meetings can be one of the classrooms, utilized after classes or as decided by the Executive Committee. Venue is subject to change depending on the activities.",
    },
    {
      _id: 9,
      section: "ELC Sessions",
      details:
        "There shall be activities-based sessions, totaling 8-10 hours in the academic year. ELC is common for all students of the college.",
    },
    {
      _id: 10,
      section: "Activities",
      details: [
        "The Electoral Literacy Guide Book for Colleges provides a detailed description of activities.",
        "Executive Committee can modify the activities or create new ones as long as they stay on the lines of voter education.",
        "The Wall Magazine Activity will be a monthly feature.",
        "Ballot Bistro and Special Registration Drive are mandatory activities.",
      ],
    },
    {
      _id: 11,
      section: "Social Media Presence and Outreach",
      points: [
        "The ELC Executive Committee is expected to post relevant content on a dedicated Facebook page and group, YouTube Channel, Pinterest board, and Instagram profile.",
        "Content should be neutral and observe best judgment and discretion.",
        "Integrate the College/University ELC's Facebook and YouTube profiles with the Election Commission of India’s Facebook page and official YouTube Channel.",
      ],
    },
    {
      _id: 12,
      section: "Mandatory Rules",
      points: [
        "ELCs shall have no association with any political party or group.",
        "None of the ELC activities should involve partisan forces.",
        "Students, regardless of political affiliations, are free to participate in ELC activities.",
        "The Executive Committee must prepare an Annual Report detailing the activities conducted and results recorded.",
      ],
    },
    {
      _id: 13,
      section: "Inclusion of Students with Disabilities",
      points: [
        "The ELCs will be an inclusive club that ensures the participation of students with disabilities.",
        "The Convener shall promote inclusion and sensitize other club members.",
        "The venue for all ELC activities should be on the ground floor for easy access.",
        "If a student with hearing impairment is attending, a sign language interpreter should be made available.",
        "No activity conducted by the club should leave behind students with disabilities.",
        "Students with disabilities will be fairly represented in the club.",
      ],
    },
    {
      _id: 14,
      section: "Activities conducted by ELC",
      points: ["Declamation", "Poster Making", "Quiz", "Slogan Writing"],
    },
  ];
  return (
    <div className="">
      {" "}
      {accData.map((acc) => (
        <Accordion type="single" collapsible>
          <AccordionItem value="item-1">
            <AccordionTrigger className="font-verdana text-xl text-red-500">
              {acc.section}
            </AccordionTrigger>
            <AccordionContent>
              {acc.details ? (
                <p className="text-gray-500 font-verdana ">{acc.details}</p>
              ) : (
                acc.points.map((point) => (
                  <li className="text-gray-500 font-verdana list-disc">
                    {point}
                  </li>
                ))
              )}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ))}
    </div>
  );
};

export default ElcAccordion;
