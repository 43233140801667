import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Navbar from "../../components/Navbar";
import Spinner from "../facultydashboard/formGroup/Spinner";
import Topbar from "../landing/Topbar";
import Footer from "../../Footer";
import ElcAccordion from "./ElcAccordion";

function ELC() {
  // console.log(data)

  const [datas, setData] = React.useState({});
  const [loader, setLoader] = useState(true);
  // console.log(datas);
  const siteData = () => {
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0]);
        setLoader(false);
        // .
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  React.useEffect(() => {
    siteData();
  }, []);
  return (
    <div>
      {loader && <Spinner />}
      <Topbar />
      <Navbar datas={datas} />
      <Breadcrumbs
        page={"Electoral Literacy Clubs (ELC)"}
        pagelink="#"
        sublink="#"
        subpage={"More"}
      />
      <div>
        <section className="flex mx-auto pt-5  px-4 w-full max-w-7xl justify-between items-center">
          <h3 className="font-bold text-3xl">No Voter to be Left Behind</h3>
          <h3 className="font-bold text-3xl">Every Vote Counts</h3>
        </section>
        <div className="mx-auto py-5 px-4 w-full max-w-7xl border-b-2 border-gray-200">
          <h3 className="text-2xl text-gray-900 font-bold">What are ELCs?</h3>
          <p className="mt-3 max-w-4xl text-sm text-gray-900">
            Electoral Literacy Clubs are being set up in the country to promote
            electoral literacy in all age groups of the Indian citizens through
            engaging and interesting activities and hands-on experience but in
            an apolitical, neutral and non-partisan manner. Electoral Literacy
            Clubs are especially being set up in colleges all across India
            targeting the new voters, (in the age group of 18-21 years old)
            pursuing their graduation. The club will have all the students from
            all semesters as its members.
          </p>

          <ElcAccordion />

          <div className="my-4 flex flex-col gap-2">
            <Link
              to="/elc-members"
              className="text-xl text-red-500 underline font-semibold"
              target="_blank"
            >
              {" "}
              List of ELC Members
            </Link>
            <div>
              <h3 className="text-xl text-red-500  font-semibold">
                {" "}
                Online resources
              </h3>
              <li className="list-decimal pl-2 text-lg text-red-500 underline font-semibold ">
                <Link
                  to="/elc"
                  target="_blank"
                  className="text-lg text-red-500 underline font-semibold"
                >
                  ELC Guide
                </Link>
              </li>
              <li className="list-decimal pl-2 text-lg text-red-500 underline font-semibold">
                <Link
                  to="https://ecisveep.nic.in/electoral-literacy-clubs/"
                  target="_blank"
                  className="text-lg text-ellipsis	 text-red-500 underline font-semibold"
                >
                  https://ecisveep.nic.in/electoral-literacy-clubs/
                </Link>
              </li>
            </div>
            <div>
              <h3 className="text-xl text-red-500  font-semibold">
                {" "}
                ElC Activities
              </h3>
              <li className="text-lg pl-2 list-decimal text-red-500 underline font-semibold">
                <Link
                  className="text-lg  text-red-500 underline font-semibold"
                  to="https://drive.google.com/file/d/1UDIIbgpCgAmwcf868awU_EjnsUiaFHAv/view?usp=sharing"
                  target="_blank"
                >
                  Link 1
                </Link>
              </li>
            </div>
            <section className="flex py-4  justify-between items-center">
              <h3 className="font-bold text-3xl">No Voter to be Left Behind</h3>
              <h3 className="font-bold text-3xl">Every Vote Counts</h3>
            </section>
            <h3 className="font-bold text-center text-3xl">
              ECI’s Helpline Number 1950
            </h3>
          </div>
        </div>
      </div>
      <Footer datas={datas} />
    </div>
  );
}

export default ELC;
